/* Contenedor principal */
/*.historial-clinico-container {
    background-color: #DAD3BE;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin: auto;
    height: 100%;
    width: 100%;
  }*/


  
  /* Cabecera con el botón de cerrar */
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    color: #333;
  }
  
 
  .boton1 {
    width: 80px; /* Ancho del botón */
    height: 70px; /* Alto del botón */
    background-color: #B7B597; /* Color de fondo del botón */
    color: black;
    border: none;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .boton1 img {
    width: 35px; /* Tamaño de la imagen */
    height: 35px; /* Tamaño de la imagen */
    margin-bottom: 5px; /* Espacio entre la imagen y el texto */
  }
  
  .boton1:hover {
    background-color: #A0937D; /* Cambio de color al pasar el ratón */
  }

  
  /* Información del paciente */
  .patient-info {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
  }
  
  .patient-info div {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  
  .patient-info label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .patient-info input {
    border: 1px solid #dfc432;
    border-radius: 5px;
    padding: 5px;
    background-color: #f9e9cf;
  }
  
 /* Estilo de la tabla */
 .table-container table {
  width: 100%; /* Ancho completo del contenedor */
  border-collapse: collapse; /* Colapsar bordes de las celdas */
  background-color: #fff; /* Color de fondo de la tabla */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Sombra sutil para la tabla */
}

.table-container th, .table-container td {
  padding: 10px; /* Espaciado interno de las celdas */
  text-align: left; /* Alineación del texto en las celdas */
  border-bottom: 1px solid #ddd; /* Borde inferior de las celdas */
}

.table-container th {
  background-color: #e9e2c0; /* Color de fondo de los encabezados */
  color: #765D00; /* Color del texto en los encabezados */
}

.table-container tr:hover {
  background-color: #f5f5f5; /* Color de fondo al pasar el ratón sobre una fila */
}


  
  .medical-button {
    width: 60px; /* Ancho del botón */
    height: 60px; /* Alto del botón */
    background-color: #B7B597; /* Color de fondo del botón */
    color: black;
    border: none;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .medical-button img {
    width: 25px; /* Tamaño de la imagen */
    height: 25px; /* Tamaño de la imagen */
    margin-bottom: 5px; /* Espacio entre la imagen y el texto */
  }
  
  .medical-button:hover {
    background-color: #A0937D; /* Cambio de color al pasar el ratón */
  }
