.button-container5 {
  display: flex;
  justify-content: center; /* Centrar los botones horizontalmente */
  gap: 10px; /* Espacio entre los botones */
  background-color: #DAD3BE; /* Color de fondo del contenedor */
  padding: 30px; /* Espaciado interno del contenedor */
  width: 680px; /* Ancho del contenedor */
  height: 80px; /* Alto del contenedor */
  overflow: auto; /* Añadir scroll si hay más contenido */
  border-radius: 5px;
}
.pacientes-container {
  
  font-family: Arial, sans-serif;
}

/* Estilo para los encabezados de sección */
.pacientes-container h2, .pacientes-container h3 {
  margin-bottom: 10px;
  color: #333;
}
  
  label {
    display: block;
    margin-bottom: 10px;
    margin-left: 25px;
  }
  
  label input {
    display: block;
    margin-top: 5px;
  }

  .button5 {
    width: 80px; /* Ancho del botón */
    height: 70px; /* Alto del botón */
    background-color: #B7B597; /* Color de fondo del botón */
    color: black;
    border: none;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .button5 img {
    width: 35px; /* Tamaño de la imagen */
    height: 35px; /* Tamaño de la imagen */
    margin-bottom: 5px; /* Espacio entre la imagen y el texto */
  }
  
  .button5:hover {
    background-color: #A0937D; /* Cambio de color al pasar el ratón */
  }

  /* Estilo de la tabla */
.pacientetabla table {
  width: 100%; /* Ancho completo del contenedor */
  border-collapse: collapse; /* Colapsar bordes de las celdas */
  background-color: #fff; /* Color de fondo de la tabla */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Sombra sutil para la tabla */
}

.pacientetabla th, .pacientetabla td {
  padding: 10px; /* Espaciado interno de las celdas */
  text-align: left; /* Alineación del texto en las celdas */
  border-bottom: 1px solid #ddd; /* Borde inferior de las celdas */
}

.pacientetabla th {
  background-color: #e9e2c0; /* Color de fondo de los encabezados */
  color: #765D00; /* Color del texto en los encabezados */
}

.pacientetabla tr:hover {
  background-color: #f5f5f5; /* Color de fondo al pasar el ratón sobre una fila */
}


/* Estilo para el campo de búsqueda */
.button-container5 input[type="text"] {
  padding: 10px; /* Espaciado interno */
  border: 1px solid #e2dec4; /* Borde del campo de texto */
  border-radius: 5px; /* Bordes redondeados */
  width: 200px; /* Ancho del campo de texto */
  margin-right: 10px; /* Espacio a la derecha del campo de texto */
}

/* Estilo para la superposición del sidebar */
.overlay {
  position: fixed; /* Posición fija en la pantalla */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
  display: flex;
  justify-content: center; /* Centrar horizontalmente */
  align-items: center; /* Centrar verticalmente */
}

/* Estilo para el contenido del sidebar */
.overlay .sidebar {
  background: white; /* Fondo blanco para el sidebar */
  padding: 20px; /* Espaciado interno del sidebar */
  border-radius: 8px; /* Bordes redondeados */
  width: 300px; /* Ancho del sidebar */
}