.button-container2 {
  display: flex;
  justify-content: center;
  gap: 10px;
  background-color: #DAD3BE;
  padding: 10px;
  width: 220px;
  height: 60px;
  overflow: auto;
  border-radius: 5px;
}

.button2 {
  width: 50px;
  height: 60px;
  background-color: #B7B597;
  color: black;
  border: none;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.button2 img {
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
}

.button2:hover {
  background-color: #A0937D;
}

.empty {
  width: 100%;
  height: 15px;
  background-color: #DAD3BE;
  margin: 20px 0;
  font-size: 10px;
}

.empty p {
  color: #000000;
  margin: 0;
  padding: 0;
  line-height: 15px;
}

.formulario2 {
  max-width: 1100px;
  margin: 0 auto;
  padding: 10px;
  background-color: #F1EEDC;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.form-row {
  display: flex;
  justify-content: space-between;
}

.form-group {
  flex: 1;
  margin-right: 10px;
}

.form-group:last-child {
  margin-right: 0;
}

input[type="text"],
input[type="date"] {
  width: 100%;
  padding: 5px;
  margin-top: 5px;
  border: 1px solid #ccc;
  background-color: #EADBC8;
  border-radius: 4px;
}

/* Contenedor para los radio buttons */
.radio-container {
  display: flex; /* Usar flexbox para alinear horizontalmente */
  align-items: center; /* Alinear verticalmente */
  gap: 10px; /* Espacio entre los radio buttons */
}

.radio-label {
  display: flex; /* Mantiene la alineación adecuada */
  align-items: center; /* Alinear verticalmente el texto y el radio button */
}

.radio-label input[type="radio"] {
  margin-right: 5px; /* Espacio entre el radio button y su etiqueta */
}

.input-group label {
  margin-bottom: 5px; /* Espacio por debajo del label principal */
}
