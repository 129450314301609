.btn-sidebar,
.btn-close-sidebar {
  display: none;
}

.button-container5 {
  overflow: hidden;
}

.button-container5>.sub {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}


@media only screen and (max-width: 600px) {
  .login-container {
    width: 80% !important;
  }

  .formularie {
    margin-top: 4rem;
    width: 100%;
  }

  .input-container {
    width: 100%;
    margin: 0;
  }

  .login-container>.formularie>.input-container>.input-label {
    margin-right: 25px !important;
    margin-left: 0;
    align-items: start;
  }

  .input-label>img {
    margin-right: 0;
    margin-left: 0;
    background: transparent;
  }

  .formularie>button {
    width: 100%;
    border-radius: 8px;
  }

  .input-field[type="password"] {
    padding: 8;
    width: 100%;
  }

  form label {
    display: flex !important;
    flex: none !important;
  }





  /* Other views */
  .principal>.container {
    flex-direction: column;
  }

  .vertical-nav.open {
    display: inline;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .vertical-nav {
    display: none;
  }

  .btn-sidebar {
    display: block;
    border-radius: 0;
    width: 2rem;
    height: 1rem;
    text-align: center;
    padding: 0.8rem 0.5rem !important;
    background: #dad3be;
    border-radius: 8px;
    color: black;
  }

  .btn-close-sidebar {

    display: block;
    border-radius: 0;
    width: 2rem;
    height: 1rem;
    text-align: center;
    padding: 0.8rem 0.5rem !important;
    background: #8d897f00;
    border-radius: 8px;
    color: black;
    float: right;
    margin-right: 3rem;
  }

  .nav-link {
    width: 80%;
  }



  .main-app {
    height: 200vh;
  }

  .main-content {
    width: 100vw;
  }

  .pacientes-container {
    width: 80vw;
  }

  h2 {
    text-wrap: wrap;
    width: 100%;
  }

  .button-container,
  .button-container5,
  .button6-container {
    width: 84%;
    flex-direction: column;
    height: auto;
  }

  .pacientes-container>.button-container {
    flex-direction: row;
  }

  .button-container5>.sub {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  .button6-container {
    width: 84%;
    flex-direction: row;
    height: auto;
    flex-wrap: wrap;
  }

  .fila-formulario,
  .form-row {
    flex-direction: column;
  }




  /* OTHER STYLES*/

  .patient-info {
    width: 80%;
    flex-direction: column;
    gap: 1rem;
  }

  .table-container {
    width: 88%;
    overflow-x: auto;
  }

  .boton1 {
    width: 1rem !important;
    height: 3rem;
    padding: 0rem 1.5rem;
    float: right;
  }

  .boton1>img {
    width: 1.2rem;
    height: auto;
    padding: 0.2rem;
  }

}
