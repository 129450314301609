/* Sidebar.css */
.sidebar {
  position: fixed;
  top: 0;
  right: -300px; /* Inicialmente oculto fuera de la vista */
  width: 1186px; /* Ancho inicial del sidebar */
  height: 93vh; /* Ocupa la altura completa de la ventana */
  background-color: #F1EEDC;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease;
  padding: 20px;

}

.sidebar.open {
  right: 0; /* Mostrar el sidebar */
}

.sidebar-content {
  margin-top: 50px;
  height: calc(93vh - 40px); /* Ajusta la altura del contenido del sidebar */
  overflow-y: auto; /* Añade scroll si el contenido es más largo */
}

.close-button img {
  width: 15px; /* Tamaño de la imagen */
  height: 20px; /* Tamaño de la imagen */
  margin-bottom: 5px; /* Espacio entre la imagen y el texto */
}

.close-button {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 10px;
  right: 10px;
  background: #B7B597;
  border: none;
  font-size: 12px;
  cursor: pointer;
  color: black;
  text-align: center;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: #A0937D; /* Cambio de color al pasar el ratón */
}
